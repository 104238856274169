export const environment = {
  production: true,
  mock: false,
  REST_URL: {
    B2C: 'https://dc.enphaseenergy.com/api/v1', // Keys are referenced in multiple places
    B2C_NODE: 'https://estimator-v1.enphaseenergy.com/api',
    SOLAR_ROOF: 'https://auto-design-tool.enphaseenergy.com',
    SAT: 'https://sat.solargraf.com',
    SOLARGRAFAPI: 'https://api.solargraf.com',
    SOLARGRAFAPP: 'https://app.solargraf.com',
    GERMNAYSE: 'https://sat.solargraf.com',
    APILAYER: "https://apilayer.net",
    BOMCALCULATOR: "https://bom-api.enphaseenergy.com/api/v1",
    ENPHASEWEBSITE: "https://enphase.com/api/v1"
  },
  RESOURCES: {
    LOGO_PATH: 'https://enlighten-files.s3.amazonaws.com/logo_imgs/',
    LOGO_SUB_PATH: '/og/',
    SFDC: 'https://enphase.com/en-us/global-system-estimator/data',
    STORE_IFRAME: 'https://staging-new-enphase.pantheonsite.io/en-us/enphase-us-installer'
  },
  ENLIGHTEN_URL: {
    main: 'https://enlighten.enphaseenergy.com',
    privacy: 'https://enphase.com/privacy',
    terms: 'https://enphase.com/terms',
    ensemble: 'https://enphase.com/en-us/ensemble-technology-enphase-installers',
    oldEstimator: 'https://estimator.enphaseenergy.com',
    userProfile: 'https://enlighten.enphaseenergy.com/admin/users/',
    thankYou: {
      'en-us': 'https://enphase.com/en-us/get-quote-thank-you',
      'en-in': 'https://enphase.com/en-in/get-quote-thank-you',
      'en-au': 'https://www4.enphase.com/en-au/get-quote-thank-you',
      'fr-fr': 'https://www4.enphase.com/fr-fr/merci-de-votre-interet',
      'de-de': 'https://www4.enphase.com/de-de/danke-fur-ihre-interesse',
      'nl-nl': 'https://www4.enphase.com/nl-nl/bedankt-voor-uw-interesse',
      'nl-be': 'https://www4.enphase.com/nl-nl/bedankt-voor-uw-interesse',
      'fr-be': 'https://www4.enphase.com/fr-fr/merci-de-votre-interet',
      'es-es': 'https://www4.enphase.com/es-es/obtener-cotizaci%C3%B3n-gracias',
      'referral': {
        'en-au': 'https://www4.enphase.com/en-au/thank-you-referral-leads'
      },
      'it-it': "https://www4.enphase.com/it-it/ottenere-preventivo-grazie"
    }
  },
  APP: {
    version: '1.7.0',
    installer_count: 5,
    selected_installers_limit: 3,
    deployTime: '{BUILD_TIME}'
  },
  KEYS: {
    SAT: "Basic WkM2S2NpcDRTME91M2V4dTlSR2xBVlFCZUF4QlRPdnA6SjBXOWNpYzhET053ZXIzOE1vcVpuV2RTMFh6RmNtckE=",
    BOM: "Partner.ErPhHBVEXO06wtPSvH/A9gAMAzAYBtzEnIgkvpPbjZylPXSL4jZgUOq4qjjl7JeF9GU5YVpOJzC/7V6q791Y5g=="
  },
  OFFGRID:true
};
